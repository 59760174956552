<template>
  <div class="page-header">
    <div class="page-header-items">
      <div v-if="showBack" class="back">
        <el-button circle @click="back">
          <el-icon>
            <arrow-left/>
          </el-icon>
        </el-button>
      </div>
      <div class="title">
        <slot name="title"/>
      </div>
      <div class="actions">
        <slot name="actions"/>
      </div>
    </div>

  </div>
</template>

<script>
import {ArrowLeft} from "@element-plus/icons-vue";

export default {
  components: {ArrowLeft},
  props: {
    title: {
      type: String
    },
    showBack: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    back() {
      this.$router.push('/');
    }
  }
}
</script>

<style scoped lang="scss">
.page-header {
  position: fixed;
  width: 100%;
  color: #303133;
  font-size: 20px;
  border-bottom: 1px solid #dcdfe6;
  background: white;
  height: 20px;
  padding: 0.75em 0;
  display: flex;
  align-items: center;
  z-index: 1;

  .page-header-items {
    margin: 0 1em;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5em;

    .title {
      flex-grow: 1;
    }
  }
}
</style>