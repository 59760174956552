import {createStore} from 'vuex';
import {jwtDecode} from "jwt-decode";

export const store = createStore({
    state() {
        return {
            auth: localStorage.getItem('galleryAuth'),
            images: [],
            nextPageImage: null,
            lastPageReached: false,
        }
    },

    actions: {
        setAuth({commit}, payload) {
            commit("setAuth", payload);
        },
        clearAuth({commit}, payload) {
            commit("clearAuth", payload);
        },
        addImages({commit}, payload) {
            commit("addImages", payload);
        },
        setNextPageImage({commit}, payload) {
            commit("setNextPageImage", payload);
        },
        setLastPageReached({commit}, payload) {
            commit("setLastPageReached", payload);
        },
        resetImages({commit}) {
            commit("resetImages");
        },
    },

    mutations: {
        setAuth(state, auth) {
            state.auth = auth;
            localStorage.setItem('galleryAuth', state.auth);
            let decoded = jwtDecode(auth);
            state.name = decoded.name;
        },
        clearAuth(state) {
            state.auth = null;
            localStorage.removeItem('galleryAuth');
        },
        addImages(state, images) {
            state.images = state.images.concat(images);
        },
        setNextPageImage(state, image) {
            state.nextPageImage = image;
        },
        setLastPageReached(state, reached) {
            state.lastPageReached = reached;
        },
        resetImages(state) {
            state.images = [];
            state.nextPageImage = null;
            state.lastPageReached = false;
        },
    }
});