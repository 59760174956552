<template>
  <img src="../assets/img/logo.svg" alt="Lorena & Carles" />
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
img {
  height: 22px;
}
</style>