<template>
  <div class="file-input">
    <el-button type="primary" size="large" @click="onButtonClick">Subir fotos</el-button>
    <input
      type="file"
      ref="upload"
      accept="image/png,image/jpeg"
      multiple
      v-on:change="onChange"
    />
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {}
  },
  data() {
    return {
      uploading: false,
      localValue: this.modelValue,
    }
  },
  methods: {
    onButtonClick() {
      this.$refs.upload.click()
    },
    onChange(e) {
      this.localValue = e.target.files;
      this.$emit('update:modelValue', this.localValue);
    }
  }
}
</script>

<style scoped lang="scss">
input[type="file"] {
  display: none;
}
</style>