<template>
  <div class="common-layout">
    <page-header>
      <template #title>
        Subir fotos
      </template>
    </page-header>
    <el-container>
      <el-main>
        <div v-if="!uploading" class="upload">
          <file-input v-model="files" @change="onChangeFiles"/>
          <p>Imágenes JPG o PNG de menos de 10 MB</p>
        </div>
        <div v-if="uploading" class="uploading">
          <p v-if="!uploadingComplete">Estamos subiendo tus imágenes...</p>
          <el-progress type="circle" :status="progressStatus" :percentage="percentage"/>
          <div v-if="uploadingComplete && !uploadingWarnings">
            <p>Tus imágenes se han subido correctamente.</p>
            <el-button @click="$router.push('/')">Volver a la galería</el-button>
          </div>
          <div v-if="uploadingComplete && uploadingWarnings">
            <p>Algunas de tus imágenes no se han podido subir. Por favor, vuelve a intentarlo.</p>
            <el-button @click="$router.push('/')">Volver a la galería</el-button>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>

import FileInput from "@/components/FileInput.vue";
import {apiAxios} from "@/service/apiClient";
import PageHeader from "@/components/PageHeader.vue";

export default {
  components: {PageHeader, FileInput},
  data() {
    return {
      uploading: false,
      uploadingComplete: false,
      uploadingWarnings: false,
      uploadingIndex: 0,
      files: [],
    }
  },
  computed: {
    progressStatus() {
      if (this.uploadingWarnings) {
        return "warning";
      }

      if (this.uploadingComplete) {
        return "success";
      }

      return null;
    },
    percentage() {
      if (this.files.length === 0) {
        return 0;
      }

      return ((this.uploadingIndex / this.files.length) * 100).toFixed(2);
    }
  },
  methods: {
    beginUpload() {
      this.uploading = true;
      this.uploadingIndex = 0;
      this.uploadNext();
    },
    uploadNext() {
      let formData = new FormData();
      formData.append("file", this.files[this.uploadingIndex]);

      apiAxios
        .post('/gallery/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(() => {
          this.uploadingIndex++;

          if (this.uploadingIndex < this.files.length) {
            this.uploadNext();
          } else {
            this.uploadingComplete = true;
          }
        })
        .catch(() => {
          this.uploadingWarnings = true;
          this.uploadingIndex++;

          if (this.uploadingIndex < this.files.length) {
            this.uploadNext();
          } else {
            this.uploadingComplete = true;
          }
        })
    },
    onChangeFiles() {
      this.beginUpload();
    }
  }
}
</script>

<style scoped lang="scss">
.upload {
  margin: 2em 0;
  text-align: center;

  p {
    font-size: 12px;
  }
}
.uploading {
  margin: 2em 0;
  text-align: center;
}
</style>