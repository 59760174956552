<template>
  <div class="gallery-wrapper" @scroll="onScroll">
    <page-header :show-back="false">
      <template #title>
        <page-logo/>
      </template>
      <template #actions>
        <el-button type="primary" size="small" round @click="goUploadImages">
          <template #icon>
            <el-icon>
              <upload/>
            </el-icon>
          </template>
          Subir fotos
        </el-button>
      </template>
    </page-header>
    <el-container class="gallery">
      <el-main>
        <el-row :gutter="2">
          <el-col v-for="image in images" :key="image" :span="8">
            <el-image style="width: 100%; height: 33vw" :src="image.thumbnailUrl" lazy fit="cover"
                      @click="preview(image.id)">
              <template #error>
                <div class="image-slot">
                  <el-icon>
                    <Picture/>
                  </el-icon>
                </div>
              </template>
            </el-image>
          </el-col>
        </el-row>

        <div class="loading" v-if="loading">
          <el-icon size="large" class="is-loading">
            <Loading/>
          </el-icon>
        </div>

        <div v-if="canLoadMore" class="load-more">
          <el-button @click="loadNextPage">Cargar más</el-button>
        </div>

        <el-row v-if="noImages">
          <el-col style="text-align: center;">
            <p>Todavía no hay ninguna foto.</p>
            <el-button size="small" type="primary" @click="goUploadImages">Sube la primera</el-button>
          </el-col>
        </el-row>

        <el-alert v-if="error" type="error" :closable="false">
          Lo sentimos, no se han podido cargar las imágenes. Vuelve a intentarlo en unos minutos.
        </el-alert>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import {Picture, Upload, Loading} from '@element-plus/icons-vue'
import {apiAxios} from "@/service/apiClient";
import PageHeader from "@/components/PageHeader.vue";
import PageLogo from "@/components/PageLogo.vue";

export default {
  components: {Upload, Loading, PageLogo, PageHeader, Picture},
  data() {
    return {
      loading: false,
      error: false,
    }
  },
  computed: {
    images() {
      return this.$store.state.images;
    },
    nextPageImage() {
      return this.$store.state.nextPageImage;
    },
    canLoadMore() {
      return !this.loading && this.nextPageImage !== null && this.images.length > 0;
    },
    noImages() {
      return !this.loading && this.images.length === 0;
    },
    lastReached() {
      return this.$store.state.lastPageReached;
    },
  },
  mounted() {
    this.$store.commit('resetImages');
    this.loadNextPage();
  },
  methods: {
    loadNextPage() {
      if (this.lastReached || this.loading) {
        return;
      }

      this.loading = true;
      this.error = false;

      apiAxios
        .get('/gallery/page/' + (this.nextPageImage || 'first'))
        .then(data => {
          this.$store.commit('addImages', data.data.images);
          this.$store.commit('setNextPageImage', data.data.next);

          if (data.data.next == null) {
            console.log("LAST!");
            this.$store.commit('setLastPageReached', true);
          }
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    preview(id) {
      this.$router.push('/view/' + id)
    },
    goUploadImages() {
      this.$router.push("/upload");
    },
    onScroll({target: {scrollTop, clientHeight, scrollHeight}}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.loadNextPage();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.gallery-wrapper {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.loading {
  text-align: center;
  margin-top: 1em;
}

.load-more {
  text-align: center;
  margin-top: 1em;
}

.el-main {
  padding: 0 0 4em 0;
}

.gallery {
  .el-alert {
    margin-top: 1em;
  }

  .el-row {
    padding: 0;
    margin: 0;

    .el-col {
      padding: 0;
      margin-bottom: -2px;

      .el-image {
        .image-slot {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          background: var(--el-fill-color-light);
          color: var(--el-text-color-secondary);
          font-size: 30px;
        }
      }
    }
  }
}
</style>