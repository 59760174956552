<template>
  <p>Cerrando sesión...</p>
</template>

<script>
import {store} from "@/store";
export default {
  mounted() {
    store.commit('clearAuth');
    this.$router.push('/');
  }
}
</script>

<style scoped lang="scss">

</style>