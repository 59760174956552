import MainPage from "@/pages/MainPage.vue";
import UploadPage from "@/pages/UploadPage.vue";
import {createRouter, createWebHistory} from "vue-router";
import LoginPage from "@/pages/LoginPage.vue";
import LogoutPage from "@/pages/LogoutPage.vue";
import ViewPage from "@/pages/ViewPage.vue";

const routes = [
    {path: '/', component: MainPage},
    {path: '/view/:id', component: ViewPage},
    {path: '/upload', component: UploadPage},
    {path: '/login', component: LoginPage},
    {path: '/logout', component: LogoutPage},
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        // always scroll to top
        return {top: 0}
    },
})

export default router