<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
  components: {},
}
</script>

<style lang="scss">
@import "assets/scss/main";

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: sans-serif;
}
</style>
