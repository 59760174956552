<template>
  <div class="view-page">
    <page-header>
      <template #title>
        <page-logo/>
      </template>
    </page-header>
    <el-container class="gallery">
      <el-main v-loading="loading">
        <template v-if="image != null">
          <div class="image-wrapper">
            <div class="image" :style="{backgroundImage: 'url(' + image.imageUrl + ')'}"/>
            <div class="button-previous">
              <el-button circle size="small" @click="previous" :disabled="imageIndex === 0">
                <el-icon>
                  <back/>
                </el-icon>
              </el-button>
            </div>
            <div class="button-next">
              <el-button circle size="small" @click="next" :disabled="isLastImage">
                <el-icon>
                  <right/>
                </el-icon>
              </el-button>
            </div>
          </div>
          <div class="info">
            <div class="username">
              <el-icon>
                <user/>
              </el-icon>
              <span class="text">{{ image.owner }}</span>
            </div>
            <div class="actions">
              <el-popconfirm
                v-if="userId == image.ownerId"
                title="¿Seguro que quieres eliminar esta foto?"
                confirm-button-type="danger"
                confirm-button-text="Sí"
                cancel-button-text="No"
                @confirm="deleteImage(image.id)"
              >
                <template #reference>
                  <el-button circle type="danger" size="small">
                    <el-icon>
                      <delete/>
                    </el-icon>
                  </el-button>
                </template>
              </el-popconfirm>
              <el-button circle size="small" @click="downloadImage">
                <el-icon>
                  <download/>
                </el-icon>
              </el-button>
            </div>
          </div>
        </template>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import PageLogo from "@/components/PageLogo.vue";
import {Back, Delete, Download, Right, User} from "@element-plus/icons-vue";
import {apiAxios} from "@/service/apiClient";
import {jwtDecode} from "jwt-decode";

export default {
  components: {Delete, Right, Back, Download, User, PageLogo, PageHeader},
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    nextPageImage() {
      return this.$store.state.nextPageImage;
    },
    imageIndex() {
      return this.$store.state.images.findIndex(i => i.id === this.$route.params.id);
    },
    image() {
      return this.$store.state.images.find(i => i.id === this.$route.params.id);
    },
    nextImage() {
      return this.$store.state.images[this.imageIndex + 1];
    },
    previousImage() {
      return this.$store.state.images[this.imageIndex - 1];
    },
    lastReached() {
      return this.$store.state.lastPageReached;
    },
    isLastImage() {
      return this.nextImage == null && this.lastReached;
    },
    userId() {
      return jwtDecode(this.$store.state.auth).id;
    },
  },
  mounted() {
    if (!this.image) {
      this.loadNextPage();
    }
  },
  methods: {
    loadNextPage() {
      if (this.lastReached || this.loading) {
        return;
      }

      this.loading = true;

      apiAxios
        .get('/gallery/page/' + (this.nextPageImage || this.$route.params.id))
        .then(data => {
          this.$store.commit('addImages', data.data.images);
          this.$store.commit('setNextPageImage', data.data.next);

          if (data.data.next == null) {
            this.$store.commit('setLastPageReached', true);
          }
        })
        .catch(() => {
        })
        .finally(() => {
          this.loading = false;
        });
    },
    next() {
      this.$router.push("/view/" + this.nextImage.id);
    },
    previous() {
      this.$router.push("/view/" + this.previousImage.id);
    },
    downloadImage() {
      const link = document.createElement('a')
      link.href = this.image.downloadUrl;
      link.download = this.image.downloadUrl;
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    deleteImage(id) {
      this.loading = true;

      let nextUrl = '/';

      if (this.nextImage) {
        nextUrl = "/view/" + this.nextImage.id;
      }

      apiAxios.delete('/gallery/' + id)
        .then(() => {
          this.$router.push(nextUrl);
        })
        .finally(() => {
          this.loading = false;
        })
    }
  },
  watch: {
    nextImage(v) {
      if (v == null) {
        this.loadNextPage();
      }
    }
  }
}
</script>

<style scoped lang="scss">
.el-main {
  padding: 0;
}

.image-wrapper {
  position: relative;

  .image {
    width: 100%;
    height: calc(100dvh - 51px - 32px);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #303133;
  }

  .button-previous {
    position: absolute;
    top: 0.5em;
    left: 0.5em;
  }

  .button-next {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
  }
}

.info {
  display: flex;
  width: 100%;
  padding: 4px 0.5em;
  box-sizing: border-box;

  .username {
    display: flex;
    flex-grow: 1;
    gap: 0.25em;
    align-items: center;

    .text {
      font-size: 0.75em;
      font-weight: bold;
    }
  }
}
</style>