<template>
  <div class="common-layout">
    <page-header :show-back="false">
      <template #title>
        <page-logo/>
      </template>
    </page-header>
    <el-container>
      <el-main>
        <el-form label-width="150px">
          <el-form-item label="¿Quién eres?">
            <el-input v-model="name" placeholder="Escribe tu nombre"/>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="login"
              :loading="loading"
              :disabled="name === ''"
            >
              Entrar
            </el-button>
          </el-form-item>
          <el-alert v-if="error" type="error" :closable="false">
            Los datos son incorrectos, vuelve a intentarlo.
          </el-alert>
        </el-form>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import PageLogo from "@/components/PageLogo.vue";
import PageHeader from "@/components/PageHeader.vue";
import {apiPublicAxios} from "@/service/apiClient";
import {store} from "@/store";

export default {
  components: {PageHeader, PageLogo},
  data() {
    return {
      error: false,
      loading: false,
      name: "",
    }
  },
  methods: {
    login() {
      this.loading = true;
      this.error = false;

      apiPublicAxios
        .post('/gallery/create-user', {name: this.name})
        .then(data => {
          store.commit('setAuth', data.data.token);
          this.$router.push('/');
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        })
    }
  }
}
</script>

<style scoped lang="scss">
.el-form {
  width: 100%;
}
</style>